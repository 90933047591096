<template>
  <div>
    <PcHeader/>
    <div style="background: white">
      <PcContentL :img="ic_img1" :txt="ic_txt1"/>
      <PcContentR :img="ic_img2" :txt="ic_txt2"/>
      <PcContentL :img="ic_img3" :txt="ic_txt3"/>
      <PcContentR :img="ic_img4" :txt="ic_txt4"/>
      <PcContentL :img="ic_img5" :txt="ic_txt5"/>
    </div>
    <PcFooter/>
  </div>
</template>

<script>
import PcHeader from './../components/PcHeader'
import PcContentL from './../components/PcContentL'
import PcContentR from './../components/PcContentR'
import PcFooter from './../components/PcFooter'

export default {
  name: "PcPage",
  components: {
    PcHeader,
    PcContentL,
    PcContentR,
    PcFooter
  },
  data() {
    return {
      ic_img1: require('./../assets/ic_img1.png'),
      ic_txt1: require('./../assets/ic_txt1.png'),
      ic_img2: require('./../assets/ic_img2.png'),
      ic_txt2: require('./../assets/ic_txt2.png'),
      ic_img3: require('./../assets/ic_img3.png'),
      ic_txt3: require('./../assets/ic_txt3.png'),
      ic_img4: require('./../assets/ic_img4.png'),
      ic_txt4: require('./../assets/ic_txt4.png'),
      ic_img5: require('./../assets/ic_img5.png'),
      ic_txt5: require('./../assets/ic_txt5.png'),

    }
  }
}
</script>

<style scoped>

</style>