<template>
  <div class="root">

    <div class="spacer"/>

    <div class="container">

      <!-- 标题 -->
      <div class="title">
        <img class="logo" alt="App logo" src="./../assets/logo.png">
        <p class="text">书迷阅读</p>
        <a class="rights" href="/rightsappeal.html" target="_self">侵权申述</a>
      </div>

      <!-- 内容 -->
      <div class="content">
        <div class="left">
          <div class="textarea">
            <p class="text">书迷阅读</p>
            <p class="text">致力于打造易用的第三方文学聚合阅读器</p>

            <p class="desc">采用最新浏览器技术的流畅阅读、内置多种性能优良的TTS语音朗读、</p>
            <p class="desc">支持第三方书源的自行导入、网络文学世界等待您的畅游！</p>
          </div>
          <div class="down">
            <div class="item">
              <p class="txt">{{ iosVersion }}</p>
              <img @click="downIos" class="img" src="./../assets/ic_down_ios_not_open.png">
            </div>
            <div class="item">
              <p class="txt">{{ anrVersion }}</p>
              <img @click="downAnr" class="img" src="./../assets/ic_down_anr.png">
            </div>
          </div>
        </div>

        <img class="right" src="./../assets/ic_header.png">
      </div>

    </div>

    <div class="spacer"/>

  </div>
</template>

<script>
export default {
  name: "AppBanner",
  data() {
    return {
      iosVersion: "暂未开放",
      anrVersion: "V1.9.2"
    }
  },
  props: {},
  methods: {
    downIos() {
    },
    downAnr() {
      window.location.href = 'https://oss.shumi.ink/apps/bookread/assets/apk/miiread_1.9.2.apk';
    }
  }
}
</script>

<style scoped>
/* level 0 */
.root {
  background: #e8f6f9;
  min-height: 30rem;

  display: flex;
  flex-direction: row;
}

.spacer {
  flex-grow: 1;
  width: auto;
  height: 0;
}

/* level 1 */
.container {
  width: 1000px;
  height: max-content;
  display: flex;
  flex-direction: column;
}

/* level 2 */
.title {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title .logo {
  width: 1.5rem;
  height: 1.5rem;
}

.title .text {
  margin-left: 15px;
  font-size: 1.5rem;
  color: #7ebbc8;
}

.title .rights {
  margin-left: 3rem;
  font-size: 1.5rem;
  color: #7ebbc8;
  text-decoration: none;
}

/* level 2 */
.content {
  height: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: row;

}

.content .left {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
}

.content .left .textarea {
  flex-grow: 1;
  text-align: left;
  padding-top: 4rem;
}

.content .left .textarea .text {
  font-size: 2rem;
  margin: 0;
}

.content .left .textarea .desc {
  font-size: 1.2rem;
  margin-top: 1.2rem;
}

.content .left .down {
  flex-grow: 0;
  margin-bottom: 2rem;

  display: flex;
  flex-direction: row;
}

.content .left .down .item {
  width: 10rem;
  height: auto;

  margin-right: 2rem;

  display: flex;
  flex-direction: column;
  align-items: start;
}

.content .left .down .item .img {
  width: 10rem;
  height: auto;
}

.content .left .down .item .txt {
  font-size: 1.2rem;
  font-weight: bold;
  /*margin-left: 0.2rem;*/
  margin: 0 0 0.2rem 0.2rem;
}

.content .right {
  flex-grow: 0;
  width: auto;
  height: 40rem;
}
</style>