<template>
  <div class="root">

    <div class="spacer"/>

    <div class="container">
      <a class="item" style="font-weight: bold; font-size: 0.9em;" href="https://qm.qq.com/cgi-bin/qm/qr?k=qKjfnLkDODOzYSsl82z5hCMxt9j_DgdD&jump_from=webapi" target="_blank">①群：1015861325</a>
      <a class="item" style="font-weight: bold; font-size: 0.9em;" href="https://qm.qq.com/cgi-bin/qm/qr?k=b89d6bHPNii4npFbyWVAa_JIzt19UiQr&jump_from=webapi" target="_blank">②群：1015752581</a>
      <a class="item" style="font-weight: bold" href="mailto:shumi@soft404.cn">shumi@soft404.cn</a>
      <a class="item" style="font-weight: bold" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备20011355号-2</a>
      <div class="item">Copyright © 2022 Anlan. All Rights Reserved.</div>
    </div>

    <div class="spacer"/>

  </div>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>
/* level 0*/
.root {
  background: #e8f6f9;
  min-height: 10rem;

  display: flex;
  flex-direction: row;
}

.spacer {
  flex-grow: 1;
  width: auto;
  height: 0;
}

/* level 1 */
.container {
  width: 1000px;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.container .item {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 1rem;
  color: #7ebbc8;
}
</style>