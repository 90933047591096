<template>
  <div v-if="isMobile()">
    <H5Page/>
  </div>
  <div v-else>
    <PcPage/>
  </div>
</template>

<script>
import H5Page from "@/components/H5Page";
import PcPage from "@/components/PcPage";

export default {
  name: 'App',
  components: {
    H5Page,
    PcPage
  },
  methods: {
    isMobile() {
      const ua = navigator.userAgent.toLowerCase();
      const t1 = /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(ua);
      return t1;
    }
  }
}
</script>

<style>
body {
  background: #e8f6f9;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
