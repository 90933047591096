<template>
  <div class="root">
    <div class="spacer"/>

    <div class="container">

      <div class="left">
        <img class="txt" :src="txt" />
      </div>

      <div class="right">
        <img class="img" :src="img"/>
      </div>

    </div>

    <div class="spacer"/>
  </div>
</template>

<script>
export default {
  name: "AppContent",
  props: {
    img: String,
    txt: String,
    textPrimary: String,
    textSummary: String,
    textNormal: String
  }
}
</script>

<style scoped>
/* level 0 */
.root {
  min-height: 30rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  display: flex;
  flex-direction: row;
}

.spacer {
  flex-grow: 1;
  width: auto;
  height: 0;
}

/* level 1 */
.container {
  width: 1000px;
  min-height: 40rem;
  display: flex;
  flex-direction: row;

  align-items: center;
}

.container .left {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;

  color: black;
  text-align: left;
  padding-bottom: 10rem;
}

.container .left .txt {
  width: 30rem;
}

.container .right {
  flex-grow: 0;

  display: flex;
  flex-direction: row;
  align-items: end;
}

.container .right .img {
  width: 30rem;
}

</style>