<template>
  <div class="root">
    <!-- 标题 -->
    <div class="title">
      <a class="rights" href="/rightsappeal.html" target="_self">侵权申述</a>
    </div>

    <!-- 内容 -->
    <img class="img" :src="ic_app1"/>
    <img class="img" :src="ic_app2"/>
    <div class="down-area">
      <div class="down-spacer"/>
      <!--      <img class="down-img" v-if="isIos()" @click="downIos" src="./../assets/ic_down_ios.png"/>-->
      <img class="down-img" @click="downAnr" src="./../assets/ic_down_anr.png"/>
      <p class="down-txt">{{ anrVersion }}</p>
      <!--      <p v-else></p>-->
    </div>
    <img class="img" :src="ic_app3"/>
    <img class="img" :src="ic_app4"/>
    <img class="img" :src="ic_app5"/>
    <img class="img" :src="ic_app6"/>
    <img class="img" :src="ic_app7"/>
    <img class="img" :src="ic_app8"/>
    <img class="img" :src="ic_app9"/>
    <img class="img" :src="ic_app10"/>
    <img class="img" :src="ic_app11"/>
    <img class="img" :src="ic_app12"/>

    <div class="footer">
      <a class="item" style="font-weight: bold; font-size: 0.9em;" href="https://qm.qq.com/cgi-bin/qm/qr?k=qKjfnLkDODOzYSsl82z5hCMxt9j_DgdD&jump_from=webapi" target="_blank">①群：1015861325</a>
      <a class="item" style="font-weight: bold; font-size: 0.9em;" href="https://qm.qq.com/cgi-bin/qm/qr?k=b89d6bHPNii4npFbyWVAa_JIzt19UiQr&jump_from=webapi" target="_blank">②群：1015752581</a>
      <a class="item" style="font-weight: bold" href="mailto:shumi@soft404.cn">shumi@soft404.cn</a>
      <a class="item" style="font-weight: bold" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备20011355号-2</a>
      <div class="item">Copyright © 2022 Anlan. All Rights Reserved.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "H5Page",
  data() {
    return {
      iosVersion: "V0.0.0",
      anrVersion: "V1.9.2",
      ic_app1: require('./../assets/ic_app1.png'),
      ic_app2: require('./../assets/ic_app2.png'),
      ic_app3: require('./../assets/ic_app3.png'),
      ic_app4: require('./../assets/ic_app4.png'),
      ic_app5: require('./../assets/ic_app5.png'),
      ic_app6: require('./../assets/ic_app6.png'),
      ic_app7: require('./../assets/ic_app7.png'),
      ic_app8: require('./../assets/ic_app8.png'),
      ic_app9: require('./../assets/ic_app9.png'),
      ic_app10: require('./../assets/ic_app10.png'),
      ic_app11: require('./../assets/ic_app11.png'),
      ic_app12: require('./../assets/ic_app12.png'),
    }
  },
  methods: {
    isIos() {
      const ua = navigator.userAgent.toLowerCase();
      const t1 = /iphone|ipad/i.test(ua);
      return t1;
    },
    isAnr() {
      const ua = navigator.userAgent.toLowerCase();
      const t1 = /android/i.test(ua);
      return t1;
    },
    downIos() {
      // window.open('https://apps.apple.com/cn/app/%E5%A5%BD%E6%80%9D%E8%AF%8D%E5%85%B8/id1632710256', '_blank');
    },
    downAnr() {
      window.location.href = 'https://oss.shumi.ink/apps/bookread/assets/apk/miiread_1.9.2.apk';
    }
  }
}
</script>

<style scoped>
/* level 0*/
.root {
  display: flex;
  flex-direction: column;
}

/* level 1 */
.title {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.title .rights {
  margin-top: 1.5rem;
  margin-right: 3rem;
  margin-left: 4.8rem;
  font-size: 1rem;
  color: #7ebbc8;
  justify-items: end;
  text-decoration: none;
}

/* level 1*/
.img {
  width: 100%;
}

.down-area {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
}

.down-area .down-spacer {
  width: 20.17%;
}

.down-area .down-img {
  width: 34.98%;
}

.down-area .down-txt {
  font-size: 1.2rem;
  font-weight: bold;
  color: #7ebbc8;

  margin-left: 2rem;
  margin-top: 0.8rem;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 3rem;

  align-items: center;
  justify-content: center;
}

.footer .item {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 1rem;
  color: #7ebbc8;
}
</style>